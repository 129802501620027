import {
  type ErrorResponse,
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from '@remix-run/react'
import {captureRemixErrorBoundaryError} from '@sentry/remix'
import {ArrowLeftIcon, HeartCrackIcon} from "lucide-react";
import {ButtonLink} from "#app/components/ui/button-link.tsx";
import {getErrorMessage} from '#app/utils/misc.tsx'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
                                       defaultStatusHandler = ({error}) => (
                                         <>
                                           {process.env.NODE_ENV === "development" && <p>
                                             {error.status} {error.data}
                                           </p>}
                                           <ButtonLink to="../" variant="yellow" className="mt-3">
                                             <ArrowLeftIcon className="w-4 h-4 mr-2"/>
                                             zurück
                                           </ButtonLink>
                                         </>
                                       ),
                                       statusHandlers,
                                       unexpectedErrorHandler = error => (
                                         <>
                                           {process.env.NODE_ENV === "development" && <p>{getErrorMessage(error)}</p>}
                                           <ButtonLink to="../" variant="yellow" className="mt-3">
                                             <ArrowLeftIcon className="w-4 h-4 mr-2"/>
                                             zurück
                                           </ButtonLink>
                                         </>
                                       ),
                                     }: {
  defaultStatusHandler?: StatusHandler
  statusHandlers?: Record<number, StatusHandler>
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  const params = useParams()

  if (typeof document !== 'undefined') {
    console.error(error)
  }

  return (
    <div className="flex flex-1 items-center justify-center p-20 bg-slate-50">
      <div
        className="p-4 max-w-full w-full flex items-center justify-center text-center">
        <div>
          <HeartCrackIcon className="w-28 h-28 text-brand mx-auto"/>
          <h1 className="text-h2">Ups, da ist etwas schief gelaufen...</h1>
          <div className="mt-2">
            {isRouteErrorResponse(error)
              ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
                error,
                params,
              })
              : unexpectedErrorHandler(error)}
          </div>
        </div>
      </div>
    </div>
  )
}
